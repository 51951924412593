declare global {
  interface Window {
    appEvent: any;
  }
}

export function postMessage(type: string, data?: any) {
  const message = data ? { type, data } : { type };

  if (window.appEvent) {
      window.appEvent.postMessage(JSON.stringify(message), '*');
  } else {
      window.parent.postMessage(JSON.stringify(message), '*');
  }
}

export function notifyLocationChanged(title: string) {
  postMessage('LOCATION_CHANGE', title);
}

export function notifyActionRequired() {
  postMessage('ACTION_REQUIRED');
}

export function notifyProfileDownloadSuccess(data: any) {
  postMessage('PROFILE_DOWNLOAD_SUCCESS', {
      provider: 'OpenVPN Cloud',
      ...data,
  });
}

export function notifyProfileDownloadFailed() {
  postMessage('PROFILE_DOWNLOAD_FAILED');
}

export function notifyConnectSuccess() {
  postMessage('CONNECT_SUCCESS');
}

export function notifyConnectFailed() {
  postMessage('CONNECT_FAILED');
}
