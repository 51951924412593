import { TRPCClientError } from "@trpc/client";
import { toDataURL } from "qrcode";
import { call, getContext, put, takeEvery } from "redux-saga/effects";
import theme from "../../theme";
import { TRPCClient } from "../../utils/rpc";
import { activateTotp, getTotpStatus, testTotp } from "./actions";

function* getTOTPStatusSaga() {
  const trpc: TRPCClient = yield getContext("trpc");

  try {
    const hastotp: boolean = yield call(trpc.vpn.hasTOTP.query);
    yield put(getTotpStatus.success(hastotp));
  } catch (error) {
    if (error instanceof TRPCClientError) {
      yield put(getTotpStatus.failure(error.message));
    } else {
      yield put(getTotpStatus.failure("unkown error"));
    }
  }
}

function* activateTOTPSaga() {
  const trpc: TRPCClient = yield getContext("trpc");

  try {
    const totpurl: string = yield call(trpc.vpn.getTOTP.query);
    const url = new URL(totpurl);
    const secret = url.searchParams.get("secret");

    if (!secret) {
      yield put(activateTotp.failure("unable to extract secret"));
      return;
    }

    // @ts-ignore
    const qrcode: string = yield call(toDataURL, totpurl, {
      color: {
        dark: theme.global.colors.brand.light,
        light: theme.global.colors.background.light,
      },
      errorCorrectionLevel: "M",
      scale: 6,
    });

    yield put(activateTotp.success({ img: qrcode, secret }));
  } catch (error) {
    if (error instanceof TRPCClientError) {
      yield put(activateTotp.failure(error.message));
    } else {
      yield put(activateTotp.failure("unkown error"));
    }
  }
}

function* testTOTPSaga(action: { payload: string }) {
  const trpc: TRPCClient = yield getContext("trpc");

  try {
    const result: boolean = yield call(
      trpc.vpn.testTOTP.query,
      action.payload
    );

    yield put(
      result ? testTotp.success() : testTotp.failure("unable to validate code")
    );
  } catch (error) {
    if (error instanceof TRPCClientError) {
      yield put(testTotp.failure(error.message));
    } else {
      yield put(testTotp.failure("unkown error"));
    }
  }
}

export default function* TOTPSaga() {
  yield takeEvery(getTotpStatus.trigger, getTOTPStatusSaga);
  yield takeEvery(activateTotp.trigger, activateTOTPSaga);
  yield takeEvery(testTotp.trigger, testTOTPSaga);
}
