export function getRefreshDelayForToken(tokenExp: number) {
  const MAX_SAFE_INTEGER = Math.pow(2, 31) - 1;
  const MIN_REFRESH_DELAY = 10000;
  const nowTimestamp = new Date();
  const expTimestamp = new Date(tokenExp * 1000);
  const refreshDelay = Math.min(
    (expTimestamp.valueOf() - nowTimestamp.valueOf()) / 2,
    MAX_SAFE_INTEGER
  );
  return {
    refreshDelay,
    shouldLogout: refreshDelay <= MIN_REFRESH_DELAY,
  };
}
