import { createSlice } from "@reduxjs/toolkit";
import { loginFailed, loginSuccess, loginTrigger } from "./actions";

export enum AuthStateState {
  PENDING,
  ERROR,
  DONE,
}

export interface AuthState {
  authState: AuthStateState;
  authToken: string | null;
  authError?: string;
}

const authSlice = createSlice<AuthState, {}>({
  name: "auth",
  initialState: {
    authState: sessionStorage.getItem("authToken") ? AuthStateState.DONE : AuthStateState.PENDING,
    authToken: sessionStorage.getItem("authToken"),
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginTrigger, (state) => {
        state.authToken = null;
        sessionStorage.removeItem("authToken");
        state.authState = AuthStateState.PENDING;
      })
      .addCase(loginSuccess, (state, action) => {
        state.authState = AuthStateState.DONE;
        state.authToken = action.payload;
        sessionStorage.setItem("authToken", action.payload);
      })
      .addCase(loginFailed, (state, action) => {
        state.authToken = null;
        state.authError = action.payload;
        state.authState = AuthStateState.ERROR;
        sessionStorage.removeItem("authToken");
      });
  },
});

export default authSlice;
