import { TRPCClientError } from "@trpc/client";
import { call, getContext, put, takeEvery } from "redux-saga/effects";
import { notifyProfileDownloadSuccess } from "../../utils/message";
import { TRPCClient } from "../../utils/rpc";
import { getProfile, getProfileToken } from "./actions";

function* getProfileTokenSaga() {
  const trpc: TRPCClient = yield getContext("trpc");

  try {
    const token: string = yield call(trpc.vpn.getProfileToken.query);
    yield put(getProfileToken.success(token));
    window.location.href = `openvpn://import-profile/https://vpn.pau.univ-pau.fr/profile/config/${token}`;
  } catch (error) {
    if (error instanceof TRPCClientError) {
      yield put(getProfileToken.failure(error.message));
    } else {
      yield put(getProfileToken.failure("unkown error"));
    }
  }
}

function* getProfileSaga() {
  const trpc: TRPCClient = yield getContext("trpc");

  try {
    const profile: string = yield call(trpc.vpn.getProfile.query);
    notifyProfileDownloadSuccess({
      title: "UPPA VPN",
      profile,
    });
    yield put(getProfile.success(profile));
  } catch (error) {
    if (error instanceof TRPCClientError) {
      yield put(getProfile.failure(error.message));
    } else {
      yield put(getProfile.failure("unkown error"));
    }
  }
}

export default function* profileSaga() {
  yield takeEvery(getProfile.trigger, getProfileSaga);
  yield takeEvery(getProfileToken.trigger, getProfileTokenSaga);
}
