import { TOTPState } from ".";

const slice = (state: any): TOTPState => state.totp;

export const selectLoading = (state: any) => slice(state).loading;
export const selectTotpState = (state: any) => slice(state).state;
export const selectHasTotp = (state: any) => slice(state).hastotp;
export const selectError = (state: any) => slice(state).error;
export const selectTotpCode = (state: any) => slice(state).totpCode;
export const selectTotpSecret = (state: any) => slice(state).totpSecret;
