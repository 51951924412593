import * as React from "react";

import { Box, Text, Spinner as GrommetSpinner } from "grommet";

const Spinner: React.FC = () => {
  return (
    <Box align="center">
      <Box pad="medium">
        <Text color="brand" weight="bold">chargement...</Text>
      </Box>
      <GrommetSpinner
        size="large"
        border={[
          { side: "all", color: "background-front", size: "medium" },
          { side: "top", color: "brand", size: "medium" },
        ]}
      />
    </Box>
  );
};

export default Spinner;