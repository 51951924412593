import {
  call,
  delay,
  getContext,
  put,
  race,
  select,
  takeEvery,
} from "redux-saga/effects";
import { TRPCClient } from "../../../utils/rpc";
import {
  loginFailed,
  loginSuccess,
  loginTrigger,
  unauthorized,
} from "./actions";
import { selectDecodedAuthToken } from "./selectors";
import type { TokenData } from "../../../../../server/src/utils/auth";
import { getRefreshDelayForToken } from "../../../utils/token";

function* refreshToken() {
  const trpc: TRPCClient = yield getContext("trpc");

  try {
    const newToken: string = yield call(trpc.auth.refreshToken.query);
    yield put(loginSuccess(newToken));
    const newDecodedToken: TokenData | null = yield select(
      selectDecodedAuthToken
    );
    return newDecodedToken;
  } catch (error) {
    yield put(unauthorized());
    console.log(error);
    return null;
  }
}

function* redirectToSso() {
  const trpc: TRPCClient = yield getContext("trpc");

  const url: string = yield call(trpc.auth.getUrl.query, {
    path: window.location.pathname,
    qs: window.location.search,
  });

  window.location.href = url;

  // wait for redirect
  yield delay(2000);
}

function* unauthorizedSaga() {
  yield redirectToSso();
}

export default function* authSaga() {
  yield takeEvery(unauthorized, unauthorizedSaga);

  const trpc: TRPCClient = yield getContext("trpc");
  const searchParams = new URLSearchParams(window.location.search);
  const ticket = searchParams.get("ticket");

  // validate sso ticket
  if (ticket) {
    yield put(loginTrigger());
    const token: string | null = yield call(trpc.auth.getToken.query, {
      ticket,
      path: window.location.pathname,
      qs: window.location.search,
    });

    if (token) {
      yield put(loginSuccess(token));
      searchParams.delete("ticket");
      const search = searchParams.toString();
      window.history.replaceState(
        undefined,
        "",
        search
          ? [window.location.pathname, "?", search].join("")
          : window.location.pathname
      );
    } else {
      yield put(loginFailed("unable to validate sso ticket"));
      return;
    }
  }

  let token: TokenData | null = yield select(selectDecodedAuthToken);

  // redirect to sso
  if (!token && !ticket) {
    yield redirectToSso();
  }

  // token refresh
  // TODO: refresh token
  while (token) {
    //yield put(loginSuccess(token));
    const { refreshDelay, shouldLogout } = getRefreshDelayForToken(token.exp!);

    const { expired }: { expired: boolean } = yield race({
      expired: delay(refreshDelay),
    });

    if (expired) {
      token = yield call(refreshToken);
    }
  }
}
