import { createSlice } from "@reduxjs/toolkit";
import {
  Anchor,
  Box,
  Button,
  Collapsible,
  Heading,
  Paragraph,
  Text,
} from "grommet";
import { Close, Configure, Download, Services, View } from "grommet-icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/spinner";
import { getProfile, getProfileToken } from "./actions";
import { selectDownloadError, selectLoading, selectProfile, selectToken } from "./selectors";

export interface ProfileState {
  loading: boolean;
  profile?: string;
  downloadError?: string;
  token?: string;
}

export const profileSlice = createSlice<ProfileState, {}>({
  name: "profile",
  initialState: {
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.trigger, (state, action) => {
        state.profile = undefined;
        state.loading = true;
      })
      .addCase(getProfile.success, (state, { payload }) => {
        state.loading = false;
        state.profile = payload;
      })
      .addCase(getProfile.failure, (state, action) => {
        state.downloadError = action.payload;
        state.loading = false;
      })
      .addCase(getProfileToken.trigger, (state, action) => {
        state.token = undefined;
        state.loading = true;
      })
      .addCase(getProfileToken.success, (state, { payload }) => {
        state.loading = false;
        state.token = payload;
      })
      .addCase(getProfileToken.failure, (state, action) => {
        state.downloadError = action.payload;
        state.loading = false;
      });
  },
});

const ProfilePage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectDownloadError);
  const profile = useSelector(selectProfile);
  const token = useSelector(selectToken);
  const [showProfile, setShowProfile] = useState(false);

  return (
    <Box align="start" gap="small">
      <Heading level="2">
        <Configure></Configure> Profil de configuration
      </Heading>

      {loading && <Spinner></Spinner>}
      {error && <div>{error}</div>}
      {profile && (
        <Box align="strech" gap="small">
          <Paragraph fill textAlign="justify">
            Le fichier de configuration est prèt, vous pouvez desormais le télécharger.
          </Paragraph>
          <Box align="center">
            <Box direction="row" gap="small">
              <Button
                href={URL.createObjectURL(
                  new Blob([profile!], {
                    type: "application/x-openvpn-profile",
                  })
                )}
                download="uppa_vpn_profile.ovpn"
                label="Télécharger le profil"
                primary
                icon=<Download></Download>
              ></Button>
              <Button
                label={`${showProfile ? "Masquer" : "Afficher"} le profil`}
                icon={showProfile ? <Close></Close> : <View></View>}
                onClick={() => setShowProfile((val) => !val)}
              ></Button>
            </Box>
          </Box>
          <Collapsible open={showProfile}>
            <Box background="focus" pad="small" round="xsmall" align="center">
              <Text size="small" as="pre">
                {profile}
              </Text>
            </Box>
          </Collapsible>
        </Box>
      )}
      {!profile && (
        <Box align="center">
          <Paragraph fill textAlign="justify">
            Un profil va être généré pour configurer votre client OpenVPN. vous devrez le télécharger puis l'installer dans votre
            client.
          </Paragraph>
          <Button
            label="Générer le profil"
            disabled={loading}
            onClick={() => {
              if (window.location.search.includes("deviceID=")) {
                dispatch(getProfileToken.trigger());
              } else {
                dispatch(getProfile.trigger());
              }
            }}
            primary
            icon=<Services></Services>
          ></Button>
        </Box>
      )}
    </Box>
  );
};

export default ProfilePage;
