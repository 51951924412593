import type { AppRouter } from "../../../server/src/trpc/main";
import {
  createTRPCProxyClient,
  httpLink,
} from "@trpc/client";
import createStore from "../store/createStore";

export const createTrpcClient = (store: ReturnType<typeof createStore>) =>
  createTRPCProxyClient<AppRouter>({
    links: [
      //customLink,
      httpLink({
        url: "/trpc",
        headers() {
          const token = store.getState().auth.authToken;
          return token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {};
        },
      }),
    ],
  });

export type TRPCClient = ReturnType<typeof createTrpcClient>;
