import { ProfileState } from ".";

const slice = (state: any): ProfileState => state.profile;

export const selectLoading = (state: any) => slice(state).loading;

export const selectDownloadError = (state: any) => slice(state).downloadError;

export const selectProfile = (state: any) => slice(state).profile;

export const selectToken = (state: any) => slice(state).token;
