import { createSlice } from "@reduxjs/toolkit";
import {
  Anchor,
  Box,
  Button,
  Heading,
  Image,
  Paragraph,
  Text,
  TextInput,
} from "grommet";
import { FingerPrint } from "grommet-icons";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/spinner";
import { getTotpStatus, activateTotp, testTotp } from "./actions";
import {
  selectError,
  selectLoading,
  selectTotpCode,
  selectTotpSecret,
  selectTotpState,
} from "./selectors";

export enum TOTPCurrentState {
  NONE,
  QRSCAN,
  VALIDATED,
  DONE,
}

export interface TOTPState {
  state: TOTPCurrentState;
  loading: boolean;
  hastotp: boolean;
  totpCode?: string;
  totpSecret?: string;
  error?: string;
}

export const TOTPSlice = createSlice<TOTPState, {}>({
  name: "totp",
  initialState: {
    state: TOTPCurrentState.NONE,
    loading: true,
    hastotp: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTotpStatus.trigger, (state) => {
        state.loading = true;
      })
      .addCase(getTotpStatus.success, (state, action) => {
        state.loading = false;
        state.hastotp = action.payload;
        state.state = action.payload
          ? TOTPCurrentState.DONE
          : TOTPCurrentState.NONE;
      })
      .addCase(getTotpStatus.failure, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(activateTotp.trigger, (state) => {
        state.loading = true;
      })
      .addCase(activateTotp.success, (state, { payload }) => {
        state.loading = false;
        state.totpCode = payload.img;
        state.totpSecret = payload.secret;
        state.hastotp = true;
        state.state = TOTPCurrentState.QRSCAN;
      })
      .addCase(activateTotp.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(testTotp.trigger, (state) => {
        state.loading = true;
      })
      .addCase(testTotp.success, (state, { payload }) => {
        state.loading = false;
        state.totpCode = undefined;
        state.totpSecret = undefined;
        state.state = TOTPCurrentState.VALIDATED;
      })
      .addCase(testTotp.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

const TOTPPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const state = useSelector(selectTotpState);
  const totpCode = useSelector(selectTotpCode);
  const totpSecret = useSelector(selectTotpSecret);
  const [code, setCode] = useState("");

  const isValidCode = useMemo(() => {
    return code.match(/^\d{6}$/);
  }, [code]);

  useEffect(() => {
    dispatch(getTotpStatus.trigger());
  }, [dispatch]);

  if (error) {
    return <div>Une erreur c'est produite, veuillez recharger la page.</div>;
  }

  /*if (loading) {
    return <div>loading...</div>;
  }*/

  return (
    <Box gap="small">
      <Heading level="2">
        <FingerPrint></FingerPrint> Mot de passe à usage unique (TOTP)
      </Heading>

      {loading && <Spinner></Spinner>}

      {state === TOTPCurrentState.DONE && (
        <Paragraph fill textAlign="justify">
          Vous avez déjà activé TOTP. Si vous avez perdu votre code, veuillez
          faire une demande de reinitialisation via un ticket sur{" "}
          <Anchor href="https://assistance.univ-pau.fr">
            https://assistance.univ-pau.fr
          </Anchor>
          .
        </Paragraph>
      )}

      {state === TOTPCurrentState.VALIDATED && (
        <Paragraph fill>Votre code OTP est maintenant operationnel.</Paragraph>
      )}

      {state === TOTPCurrentState.NONE && !loading && (
        <Box align="start">
          <Paragraph fill>
            Vous pouvez activer TOTP (mot de passe à usage unique) pour
            sécuriser votre accès VPN. <strong>Attention</strong>, une fois que
            vous aurez cliqué sur le bouton ci-dessous, vous ne pourrez plus
            vous connecter au VPN sans utiliser un mot de passe à usage unique.
          </Paragraph>
          <Button
            primary
            label="activer TOTP"
            disabled={loading}
            onClick={() => {
              setCode("");
              dispatch(activateTotp.trigger());
            }}
          ></Button>
        </Box>
      )}

      {state === TOTPCurrentState.QRSCAN && (
        <Box flex={false}>
          <Paragraph fill textAlign="justify">
            Etape 1 - Scanner ce QRCode avec votre application mobile de
            génération de codes TOTP (FreeOTP, Google Authenticator, ...)
          </Paragraph>
          <Paragraph fill color="brand" textAlign="justify">
            Attention, ce QR-code ne pourra plus être affiché une fois activé.
            Si cette page est rechargée sans avoir éffectué l'activation (étape
            2), un nouveau QR-code sera généré et devra être scanné.
          </Paragraph>
          <Box align="center">
            <Image alt="qrcode" src={totpCode}></Image>
            <Text>Secret TOTP :</Text>
            <Text as="pre">{totpSecret}</Text>
          </Box>
          <Paragraph fill textAlign="justify">
            Etape 2 - Fournir immédiatement un code à 6 chiffres généré par
            l'application mobile dans le formulaire ci dessous pour qu'il soit
            vérifié puis activé.
          </Paragraph>
          <Box align="center">
            <Box direction="row" gap="small" align="center">
              <Box width="small">
                <TextInput
                  type="text"
                  placeholder="123456"
                  value={code}
                  onChange={(v: any) => setCode(v.target.value)}
                  size="6"
                ></TextInput>
              </Box>
              <Button
                primary
                label="Tester et activer TOTP"
                disabled={loading || !isValidCode}
                onClick={() => {
                  setCode("");
                  dispatch(testTotp.trigger(code));
                }}
              ></Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TOTPPage;
