import jwtDecode from "jwt-decode";
import { createSelector } from "reselect";
import { AuthState } from ".";

const slice = (state: any): AuthState => state.auth;

function makeSelectDecodedToken(
  rawTokenSelector: (state: any) => string | null
) {
  return createSelector(rawTokenSelector, (token) => {
    if (typeof token !== "string") {
      return null;
    }
    try {
      return jwtDecode(token);
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error("Failed to decode JWT", e); // eslint-disable-line
      }
      return null;
    }
  });
}

export const selectAuthState = (state: any) => slice(state).authState;

export const selectAuthToken = (state: any) => slice(state).authToken;

export const selectDecodedAuthToken = makeSelectDecodedToken(selectAuthToken);

export const selectAuthError = (state: any) => slice(state).authError;
