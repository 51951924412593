import { Anchor, Box, Heading, Markdown, Paragraph } from "grommet";
import { CircleQuestion } from "grommet-icons";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router";

const DOC = `
### Installation Windows/OSX/Android/IOS

* Télécharger et installer le logiciel **OpenVPN Connect** (version la plus récente disponible) disponible ici : https://openvpn.net/client/
* Lancer le logiciel puis cliquer sur l'icone **+** en bas à droite pour ajouter un profil.
* En restant sur l'onglet **VIA URL**, utiliser l'adresse **${window.location.origin}**
* Cliquer sur **NEXT** puis sur **SIGN IN**, un navigateur s'ouvre alors.
* S'authentifier (CAS) puis cliquer sur "Générer le profil".
* Cliquer sur **CONFIRM** pour accepter l'importation du profil.
* Un profil **identifiant@uppa** doit alors être créé. Il suffit de cliquer dessus pour lancer la connexion.
* **Facultatif mais néanmoins recommandé** : l'authentification à deux facteurs peut être activée en suivant les instructions ci-dessous.

### Installation Linux

Linux ne dispose pas (encore ?) d'une version du client **OpenVPN Connect**. Il faudra donc installer, en fonction
de la distribution, le client "ligne de commande" openvpn3 :

https://community.openvpn.net/openvpn/wiki/OpenVPN3Linux

Une fois installé, il faudra télécharger manuellement la configuration du profil UPPA [ici](/profile), puis utiliser le client depuis un terminal.

Importer le fichier de profil :

\`\`\`
openvpn3 config-import -c NOM_DU_FICHIER -n uppa --persistent
\`\`\`

Ensuite pour démarrer, lister et stopper la connexion :

\`\`\`
openvpn3 session-start -c uppa
openvpn3 sessions-list
openvpn3 session-manage -c uppa -D
\`\`\`

### Authentification à deux facteurs

Il est possible, pour plus de sécurité, d'ajouter à l'authentification traditionelle (votre identifiant et mot de passe)
une authentification par mot de passe à usage unique (TOTP). Avant de la mettre en place, il faudra installer un logiciel
dédié sur un smartphone (il en existe egalement pour ordinateurs) qui servira à générer ces mots de passe. Voici une
liste non exhaustive de logiciels :

* FreeOTP Authenticator (Android/IOS)
* Google Authenticator (Android/IOS)

Pour l'activer, il suffit de suivre [cette démarche](/totp).
`;

const JustifiedParagraph = ({ children }: PropsWithChildren) => {
  return (
    <Paragraph fill textAlign="justify">
      {children}
    </Paragraph>
  );
};

const RoutedAnchor = ({
  children,
  href,
}: PropsWithChildren<{ href: string }>) => {
  const navigate = useNavigate();

  return href.match(/^http/) ? (
    <Anchor href={href}>{children}</Anchor>
  ) : (
    <Anchor
      onClick={() => {
        navigate(href);
      }}
    >
      {children}
    </Anchor>
  );
};

const DocPage = () => {
  return (
    <Box>
      <Heading level="2">
        <CircleQuestion /> Documentation
      </Heading>

      <Markdown components={{ p: JustifiedParagraph, a: RoutedAnchor }}>
        {DOC}
      </Markdown>
    </Box>
  );
};

export default DocPage;
