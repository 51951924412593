import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { profileSlice } from "../pages/profile";
import profileSaga from "../pages/profile/saga";
import { TOTPSlice } from "../pages/totp";
import TOTPSaga from "../pages/totp/saga";
import { createTrpcClient } from "../utils/rpc";

import authSlice from "./slices/auth";
import authSaga from "./slices/auth/saga";

function* rootSaga() {
  yield all([authSaga(), profileSaga(), TOTPSaga()]);
}

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const mainReducer = combineReducers({
    auth: authSlice.reducer,
    profile: profileSlice.reducer,
    totp: TOTPSlice.reducer,
  });

  const store = configureStore({
    reducer: mainReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware),
  });

  sagaMiddleware.setContext({
    trpc: createTrpcClient(store),
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export default createStore;
