import { Box, grommet, Grommet, Page, PageContent } from "grommet";
import { deepMerge } from "grommet/utils";
import { Outlet, useNavigation } from "react-router-dom";
import NavBar from "../components/navbar";
import ProfilePage from "../pages/profile";
import AppTheme from "../theme";

export const theme = deepMerge(grommet, AppTheme);

const Root = () => {
  const navigation = useNavigation();

  return (
    <Grommet full theme={theme} themeMode="light">
      <Box overflow="hidden" fill>
        <NavBar hideMenu={window.location.search.includes("deviceID=")}></NavBar>
        {navigation.state === "loading" && <div>loading</div>}
        <Page pad="xsmall" kind="narrow" overflow={{ vertical: "auto" }}>
          <PageContent flex={false}>
            <Outlet />
          </PageContent>
        </Page>
      </Box>
    </Grommet>
  );
};

export default Root;
