import React from "react";
import { Anchor, Box, Header, Heading, Image, Nav } from "grommet";
import { Shield, User } from "grommet-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TokenData } from "../../../server/src/utils/auth";
import { selectDecodedAuthToken } from "../store/slices/auth/selectors";

interface NavBarProps {
  hideMenu: boolean;
}

const NavBar: React.FC<NavBarProps> = ({ hideMenu }) => {
  const navigate = useNavigate();
  const token = useSelector(selectDecodedAuthToken) as TokenData;

  return (
    <Header flex={false} background="brand" pad={{horizontal: "medium", vertical: "xsmall"}}>
      <Box direction="row" align="center" gap="medium">
        <Image src="/logo.svg" height="50px"></Image>
        <Heading level="3">VPN UPPA</Heading>
      </Box>
      { hideMenu || <Box direction="row" align="center" gap="medium">
        <Nav direction="row">
          <Anchor color="white" onClick={() => navigate("profile")}>
            CONFIGURATION
          </Anchor>
          <Anchor color="white" onClick={() => navigate("totp")}>
            MFA/OTP
          </Anchor>
          <Anchor color="white" onClick={() => navigate("doc")}>
            DOCUMENTATION
          </Anchor>
        </Nav>
        <Box direction="row" align="center" gap="xsmall">
          <User color="focus" size="medium"></User>
          <Heading color="focus" level={4}>
            {token.name.toUpperCase()}
          </Heading>
        </Box>
      </Box>
      }
    </Header>
  );
};

export default NavBar;
