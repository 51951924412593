import "./App.css";
import { useSelector } from "react-redux";
import {
  selectAuthError,
  selectAuthState,
} from "./store/slices/auth/selectors";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from "./routes/root";
import { AuthStateState } from "./store/slices/auth";
import ProfilePage from "./pages/profile";
import TOTPPage from "./pages/totp";
import DocPage from "./pages/doc";
import Index from "./pages/default";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: ({ request }) => {
      // const url = new URL(request.url);
      // const searchParams = new URLSearchParams(window.location.search);
      /*if (searchParams.has("ticket")) {
        searchParams.delete("ticket");
      }*/
      /*if (searchParams.has("deviceID") && url.pathname.search(/prfile/)) {
        return redirect("profile?"+searchParams.toString());
      }*/

      return null;
    },
    children: [
      {
        index: true,
        element: <Index />,
      },
      {
        path: "totp",
        element: <TOTPPage />,
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "openvpn-api/profile",
        element: <ProfilePage />,
      },
      {
        path: "doc",
        element: <DocPage />,
      },
    ],
  },
]);

function App() {
  const authState = useSelector(selectAuthState);
  const authError = useSelector(selectAuthError);

  if (authState === AuthStateState.PENDING) {
    return <div>Authentication in progress...</div>;
  }

  if (authState === AuthStateState.ERROR) {
    return <div>{authError || "unknown authentication error"}</div>;
  }

  return <RouterProvider router={router} />;
}

export default App;
