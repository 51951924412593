import React from "react";
import { Box, Heading, Paragraph, Image, Anchor } from "grommet";
import { Connect } from "grommet-icons";
import { useNavigate } from "react-router-dom";

const DefaultPage = () => {
  const navigate = useNavigate();
  
  return (
    <Box align="start" gap="small">

      <Heading level="2">
        <Connect></Connect> VPN UPPA
      </Heading>

      <Image src="cropped-openvpn-192x192.png"></Image>

      <Box align="strech" gap="small">
        <Paragraph fill textAlign="justify">
          Une nouvelle version du concentrateur VPN de l'Université est dès à présent disponible. Pour rappel, ce concentrateur permet,
          via un logiciel installé sur votre système d'exploitation, d'accèder à un certain nombre de ressources numeriques qui sont
          normalement inaccessibles lorsque vous vous situez hors du réseau de l'université ou sur un réseau sans-fil comme eduroam.
          Ce système est ouvert à tous, mais les ressources disponibles vont être différentes selon des droits attribués individuellement.
        </Paragraph>
        <Paragraph fill textAlign="justify">
          A terme, ce concentrateur va totalement remplacer le précedent. Une phase de transition permettra de continuer à utiliser l'ancien
          concentrateur, mais ce dernier sera coupé definitivement le ???
        </Paragraph>
        <Paragraph fill textAlign="justify">
          Pour beneficier de l'accès à ce nouveau concentrateur VPN, il vous suffit de suivre la documentation disponible
          &nbsp;<Anchor onClick={() => navigate("doc")}>ici</Anchor>
        </Paragraph>
      </Box>
    </Box>
  );
};

export default DefaultPage;
